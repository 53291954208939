import React, { useState } from "react";

import Header from "../partials/Header";
import { Link } from "react-router-dom";
import PageIllustration from "../partials/PageIllustration";

function SignUp() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          aria-hidden="true"
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                <h1 className="h1">
                  Welcome. We exist to make entrepreneurship easier.
                </h1>
              </div>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <div className="flex items-center my-6">
                  <div className="inline">
                    <div className="text-black">
                      Warning: If your organization already has an account, you
                      can request access{" "}
                      <a className="text-white underline" href="/">
                        here
                      </a>
                      .
                    </div>
                  </div>
                </div>
                <form>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="full-name"
                      >
                        Full Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        required
                        className="form-input w-full text-gray-300"
                        id="full-name"
                        placeholder="First and last name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="company-name"
                      >
                        Company Name <span className="text-red-600">*</span>
                      </label>
                      <input
                        required
                        className="form-input w-full text-gray-300"
                        id="company-name"
                        placeholder="Your company or app name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Work Email <span className="text-red-600">*</span>
                      </label>
                      <input
                        required
                        className="form-input w-full text-gray-300"
                        id="email"
                        placeholder="you@yourcompany.com"
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-300 text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        Password <span className="text-red-600">*</span>
                      </label>
                      <input
                        required
                        className="form-input w-full text-gray-300"
                        id="password"
                        placeholder="Password (at least 10 characters)"
                        type="password"
                      />
                    </div>
                  </div>
                  <div className="text-sm text-gray-500 text-center">
                    I agree to be contacted by Open PRO about this offer as per
                    the Open PRO{" "}
                    <Link
                      className="underline text-black hover:text-black hover:no-underline transition duration-150 ease-in-out"
                      to="#"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">
                        Sign up
                      </button>
                    </div>
                  </div>
                </form>
                <div className="text-black text-center mt-6">
                  Already using Open PRO?{" "}
                  <Link
                    className="text-blue-600 hover:text-black transition duration-150 ease-in-out"
                    to="signin"
                  >
                    Sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default SignUp;
