import React from "react";

function Timeline() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4">Cutting edge from the beginning.</h2>
            <p className="text-xl text-black">
              Cutting edge technology that you can trust. Built on technical
              efficiency and superior customer service.
            </p>
          </div>

          {/* Items */}
          <div
            data-aos-id-timeline
            className="max-w-3xl mx-auto -my-4 md:-my-6"
          >
            {/* 1st item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-timeline]"
            >
              <div className="pl-2">
                <div className="text-xl text-blue-600 mb-2">The seed</div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2018
                  </div>
                  <div
                    aria-hidden="true"
                    className="absolute left-0 h-full px-px bg-gray-200 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                  ></div>
                  <div
                    aria-hidden="true"
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                  ></div>
                  <h4 className="h4 mt-2">
                    ever<span style={{ color: "#1890ff" }}>on</span>ics
                    <span>
                      <sup style={{ fontSize: 5, verticalAlign: "super" }}>
                        TM
                      </sup>
                    </span>{" "}
                    was founded.
                  </h4>
                </div>
                <p className="text-lg text-black">
                  A vision was born. For the next two years, we built our
                  vision, focused on backend design for scalable technology
                  services, and planned to build one of the best technology
                  services businesses out there.
                </p>
              </div>
            </div>

            {/* 2nd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-timeline]"
              data-aos-delay="200"
            >
              <div className="pl-2">
                <div className=" text-xl text-blue-600 mb-2">
                  An official start
                </div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2021
                  </div>
                  <div
                    aria-hidden="true"
                    className="absolute left-0 h-full px-px bg-gray-200 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                  ></div>
                  <div
                    aria-hidden="true"
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                  ></div>
                  <h4 className="h4 mt-3">Official development begins.</h4>
                </div>
                <p className="text-lg text-black">
                  {" "}
                  Embarked on our first project: creating the future of
                  inventory management.
                </p>
              </div>
            </div>

            {/* 3rd item */}
            <div
              className="relative py-4 md:py-6 pl-24"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-timeline]"
              data-aos-delay="400"
            >
              <div className="pl-2">
                <div className=" text-xl text-blue-600 mb-2">Go for launch</div>
                <div className="flex items-center mb-3">
                  <div className="absolute left-0 inline-flex text-sm font-semibold py-1 px-3 text-green-600 bg-green-200 rounded-full">
                    2021
                  </div>
                  <div
                    aria-hidden="true"
                    className="absolute left-0 h-full px-px bg-gray-200 ml-20 self-start transform -translate-x-1/2 translate-y-3"
                  ></div>
                  <div
                    aria-hidden="true"
                    className="absolute left-0 w-2 h-2 bg-blue-600 border-4 box-content border-gray-900 rounded-full ml-20 transform -translate-x-1/2"
                  ></div>
                  <h4 className="h4 mt-3">Inventory Launch: late 2021.</h4>
                </div>
                {/*<p className="text-lg text-black">With thousands of hours of research and tens of thousands of lines of code written,   ever<span style={{color:"#1890ff"}}>on</span>ics<span><sup style={{fontSize:5, verticalAlign:"super"}}>TM</sup></span> inventory officially launched. </p>*/}
              </div>
            </div>

            {/* 4th item 
            <div className="relative py-4 md:py-6 pl-24" data-aos="fade-up" data-aos-anchor="[data-aos-id-timeline]" data-aos-delay="600">
              <div className="pl-2">
                <div className=" text-xl text-blue-600 mb-2">The future awaits.</div>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Timeline;
