import React, { useEffect, useRef, useState } from "react";

import PageIllustration from "../partials/PageIllustration";
import Transition from "../utils/Transition.js";

function Tabs() {
  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height =
        tabs.current.children[tab - 1].offsetHeight + "px";
    }
  };

  useEffect(() => {
    heightFix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">
          {/* Section header */}
          <div data-aos-id-tabs className="max-w-3xl mx-auto text-center pb-12">
            <h2
              className="h2 mb-4"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-tabs]"
            >
              Powerful features at your fingertips.
            </h2>
            <p
              className="text-xl text-black"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-tabs]"
              data-aos-delay="200"
            >
              With a modern and responsive design, our cloud-based portal makes
              things easy. Need installable software? We also have you covered.
            </p>
          </div>

          {/* Section content */}
          <div>
            {/* Tabs buttons */}
            <div
              className="flex flex-wrap justify-center -m-2"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-tabs]"
              data-aos-delay="400"
            >
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-white hover:border-blue-600 focus:border-blue-600  border  rounded-full group transition duration-500 ${
                  tab !== 1 && "opacity-50"
                }`}
                onClick={() => setTab(1)}
              >
                <svg
                  className="w-4 h-4 fill-current text-blue-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13.5 5.5c-.311.001-.62.061-.909.177l-2.268-2.268c.116-.29.176-.598.177-.909a2.5 2.5 0 00-5 0c.001.311.061.62.177.909L3.409 5.677A2.473 2.473 0 002.5 5.5a2.5 2.5 0 000 5c.311-.001.62-.061.909-.177l2.268 2.268c-.116.29-.176.598-.177.909a2.5 2.5 0 105 0 2.473 2.473 0 00-.177-.909l2.268-2.268c.29.116.598.176.909.177a2.5 2.5 0 100-5zM8 11c-.311.001-.62.061-.909.177L4.823 8.909a2.423 2.423 0 000-1.818l2.268-2.268a2.423 2.423 0 001.818 0l2.268 2.268a2.423 2.423 0 000 1.818l-2.268 2.268A2.473 2.473 0 008 11z" />
                </svg>
                <span className="text-black transition-colors duration-150 ease-in-out">
                  The Data Grid
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-white hover:border-blue-600 focus:border-blue-600 border  rounded-full group transition duration-500 ${
                  tab !== 2 && "opacity-50"
                }`}
                onClick={() => setTab(2)}
              >
                <svg
                  className="w-4 h-4 fill-current text-blue-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.043 6.293S9.79 1.905 6.745 0A5.37 5.37 0 014.72 3.887C3.42 5.03.974 7.6 1 10.34A6.285 6.285 0 004.451 16a3.984 3.984 0 011.394-2.755 3.253 3.253 0 001.246-2.185 5.856 5.856 0 013.1 4.881v.013a5.883 5.883 0 003.428-5.106c.216-2.574-1.194-6.074-2.445-7.218a6.793 6.793 0 01-2.13 2.663z" />
                </svg>
                <span className="text-black group-hover:text-black transition-colors duration-150 ease-in-out">
                  Maintain
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-white hover:border-blue-600 focus:border-blue-600 border  rounded-full group transition duration-500 ${
                  tab !== 3 && "opacity-50"
                }`}
                onClick={() => setTab(3)}
              >
                <svg
                  className="w-4 h-4 fill-current text-blue-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 10c-1.1 0-2-.9-2-2 0-.2 0-.3.1-.5L3.6 5 5 3.6l2.5 2.5c.2-.1.3-.1.5-.1 1.1 0 2 .9 2 2s-.9 2-2 2z" />
                </svg>
                <span className="text-black group-hover:text-black transition-colors duration-150 ease-in-out">
                  Reconciliation
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-white hover:border-blue-600 focus:border-blue-600 border  rounded-full group transition duration-500 ${
                  tab !== 4 && "opacity-50"
                }`}
                onClick={() => setTab(4)}
              >
                <svg
                  className="w-4 h-4 fill-current text-blue-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8 9v6a8 8 0 008-8V1a8 8 0 00-8 8zM0 6v3a6 6 0 006 6v-3a6 6 0 00-6-6z" />
                </svg>
                <span className="text-black group-hover:text-black transition-colors duration-150 ease-in-out">
                  Admin Controls
                </span>
              </button>
              <button
                className={`flex items-center font-medium py-2 px-4 m-2 bg-white hover:border-blue-600 focus:border-blue-600 border rounded-full group transition duration-500 ${
                  tab !== 5 && "opacity-50"
                }`}
                onClick={() => setTab(5)}
              >
                <svg
                  className="w-4 h-4 fill-current text-blue-600 mr-2"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M14.936 6.007H8.358l1.594-4.685c.3-.997-.897-1.794-1.694-.997L.284 8.3c-.598.598-.199 1.694.698 1.694H7.56l-1.594 4.685c-.3.997.897 1.794 1.694.997L15.633 7.7c.598-.598.2-1.694-.697-1.694z" />
                </svg>
                <span className="text-black group-hover:text-black transition-colors duration-150 ease-in-out">
                  Settings Management
                </span>
              </button>
            </div>

            {/* Tabs items */}
            <div
              ref={tabs}
              className="relative flex flex-col mt-16"
              data-aos="fade-up"
            >
              {/* Item 1 */}
              <Transition
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterEnd="opacity-100 scale-100"
                enterStart="opacity-0 scale-98"
                leave="transition ease-out duration-300 transform absolute"
                leaveEnd="opacity-0 scale-98"
                leaveStart="opacity-100 scale-100"
                show={tab === 1}
              >
                <article className="relative max-w-md mx-auto md:max-w-none border border-blue-500">
                  <figure className="md:relative md:inset-y-0 md:right-0 md:w-1/2">
                    <PageIllustration />
                  </figure>

                  <div className="relative  bg-white bg-opacity-75 shadow-lg m-5 py-8 md:py-16 p-6 md:max-w-lg lg:max-w-xl ">
                    <h4 className="h4 mb-2">Insights at your fingertips.</h4>
                    <p className="text-lg text-black">
                      <br />
                      Group items by column, rolling up the data to see a
                      birds-eye view of generic stats and to help narrow your
                      search. From the grid, accessing and editing data is made
                      easy. Access item, product, or location-level logs in and
                      edit core data in subsequent screens, all within a
                      double-click.
                    </p>
                    {/*
                    <a
                      className="btn-sm text-white bg-blue-600 hover:bg-blue-700 mt-6"
                      href="#0"
                    >
                      <span className="text-sm">Learn more</span>
                      <svg
                        className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </a>*/}
                  </div>
                </article>
              </Transition>

              {/* Item 2 */}
              <Transition
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterEnd="opacity-100 scale-100"
                enterStart="opacity-0 scale-98"
                leave="transition ease-out duration-300 transform absolute"
                leaveEnd="opacity-0 scale-98"
                leaveStart="opacity-100 scale-100"
                show={tab === 2}
              >
                <article className="relative max-w-md mx-auto md:max-w-none border border-blue-500">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <PageIllustration />{" "}
                  </figure>
                  <div className="relative bg-white bg-opacity-75 shadow-lg m-5 py-8 md:py-16 p-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2">Maintain your inventory.</h4>
                    <p className="text-lg text-black">
                      <br />
                      After building out your dynamic fields for items,
                      products, and locations, give your team access to transact
                      inventory. <br />
                      <br />
                      Add locations, products, and items that you connect to
                      products and locations. You can also move items and
                      perform replacement operations if needed.
                    </p>
                    {/*<a
                      className="btn-sm text-white bg-blue-600 hover:bg-blue-700 mt-6"
                      href="#0"
                    >
                      <span className="text-sm">Learn more</span>
                      <svg
                        className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </a>*/}
                  </div>
                </article>
              </Transition>

              {/* Item 3 */}
              <Transition
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterEnd="opacity-100 scale-100"
                enterStart="opacity-0 scale-98"
                leave="transition ease-out duration-300 transform absolute"
                leaveEnd="opacity-0 scale-98"
                leaveStart="opacity-100 scale-100"
                show={tab === 3}
              >
                <article className="relative max-w-md mx-auto md:max-w-none border border-blue-500">
                  <figure className="md:relative md:inset-y-0 md:right-0 md:w-1/2">
                    <PageIllustration />{" "}
                  </figure>
                  <div className="relative  bg-white bg-opacity-75 shadow-lg m-5 py-8 md:py-16 p-6 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2">Recon Ops.</h4>
                    <p className="text-lg text-black">
                      <br />
                      All inventory needs to be reconciled. Whether you are
                      performing continuous recon or perform recon once a year,
                      you can manage that process through our reconciliation
                      dashboard. Once a recon process is started, your team can
                      get to work verifying your inventory. <br />
                      <br />
                      Monitor this process through the dashboard as items are
                      reconciled or flagged for further investigation.
                    </p>
                    {/* <a
                      className="btn-sm text-white bg-blue-600 hover:bg-blue-700 mt-6"
                      href="#0"
                    >
                      <span className="text-sm">Learn more</span>
                      <svg
                        className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                   </a>*/}
                  </div>
                </article>
              </Transition>

              {/* Item 4 */}
              <Transition
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterEnd="opacity-100 scale-100"
                enterStart="opacity-0 scale-98"
                leave="transition ease-out duration-300 transform absolute"
                leaveEnd="opacity-0 scale-98"
                leaveStart="opacity-100 scale-100"
                show={tab === 4}
              >
                <article className="relative max-w-md mx-auto md:max-w-none border border-blue-500">
                  <figure className="md:absolute md:inset-y-0 md:left-0 md:w-1/2">
                    <PageIllustration />{" "}
                  </figure>
                  <div className="relative bg-white bg-opacity-75 shadow-lg m-5 py-8 md:py-16 p-6 md:pl-16 md:max-w-lg lg:max-w-xl md:ml-auto">
                    <h4 className="h4 mb-2">Manage your team.</h4>
                    <p className="text-lg text-black">
                      Manage all of your users and what they have access to. You
                      can immediately restrict user access, even if they're
                      logged in. You can also provision users and view their
                      onboarding status, manage user defined fields, manage
                      groups, permissions, and more..
                    </p>
                    {/*<a
                      className="btn-sm text-white bg-blue-600 hover:bg-blue-700 mt-6"
                      href="#0"
                    >
                      <span className="text-sm">Learn more</span>
                      <svg
                        className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </a>*/}
                  </div>
                </article>
              </Transition>

              {/* Item 5 */}
              <Transition
                appear={true}
                className="w-full"
                enter="transition ease-in-out duration-500 transform order-first"
                enterEnd="opacity-100 scale-100"
                enterStart="opacity-0 scale-98"
                leave="transition ease-out duration-300 transform absolute"
                leaveEnd="opacity-0 scale-98"
                leaveStart="opacity-100 scale-100"
                show={tab === 5}
              >
                <article className="relative max-w-md mx-auto md:max-w-none border border-blue-500">
                  <figure className="md:relative md:inset-y-0 md:right-0 md:w-1/2">
                    <PageIllustration />{" "}
                  </figure>
                  <div className="relative bg-white bg-opacity-75 shadow-lg m-5 py-8 md:py-16 p-6 md:pr-16 md:max-w-lg lg:max-w-xl">
                    <h4 className="h4 mb-2">Settings</h4>
                    <p className="text-lg text-black">
                      Each user can manage their preferences and account
                      settings including notifications and security settings. If
                      you are the owner, manage your plan through our
                      PCI-compliant portal as well. We plan to add suport to
                      org-level MFA on top of our current 2FA requirements.
                    </p>
                    {/*<a
                      className="btn-sm text-white bg-blue-600 hover:bg-blue-700 mt-6"
                      href="#0"
                    >
                      <span className="text-sm">Learn more</span>
                      <svg
                        className="w-3 h-3 fill-current text-blue-400 flex-shrink-0 ml-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                      </svg>
                    </a>*/}
                  </div>
                </article>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tabs;
