import React, { useState } from "react";

import Modal from "../utils/Modal";
import Particles from "react-particles-js";

function HeroHome({ setIsModalVisible }) {
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <>
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
          {/* Illustration behind hero content */}
          <div
            className="z-0 w-full absolute"
            data-aos="fade-up"
            data-aos-delay="1000"
          >
            <Particles
              params={{
                particles: {
                  color: {
                    value: "#1890ff",
                  },
                  links: {
                    color: "#1890ff",
                    distance: 200,
                    enable: true,
                    opacity: 1,
                    width: 0.5,
                  },
                  collisions: {
                    enable: false,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    random: false,
                    speed: 1,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      value_area: 800,
                    },
                    value: 40,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    random: false,
                    value: 2,
                  },
                },
              }}
            />
          </div>

          {/* Hero content */}
          <div className="relative pt-16 pb-10 md:pt-40 md:pb-16">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16 mt-24">
              <h1 className="h3 mb-4" data-aos="fade-up">
                The future of inventory management.
              </h1>
              <p
                className="text-lg text-black mb-8"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                A highly flexible inventory system geared towards any storage
                model. With completely custom fields and advanced asset
                reconciliation, we give you tools and services to do inventory
                the right way.
              </p>
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div data-aos="fade-up" data-aos-delay="400">
                  {/*<a className="btn text-white bg-blue-600 hover:bg-blue-500 hover:text-white w-full mb-4 sm:w-auto sm:mb-0" href="#0">Start free trial</a>*/}
                  <p className="text-lg h2 text-black mt-3 mb-5">Launching soon!</p>
                </div>
                <div data-aos="fade-up" data-aos-delay="600">
                  <a
                    className="btn text-white bg-blue-600 hover:bg-blue-500 hover:text-white w-full sm:w-auto sm:ml-4"
                    href="https://cdn.forms-content.sg-form.com/eacfac2b-e0f8-11eb-a629-9a5fe9ac330b"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Get notified
                  </a>
                </div>
              </div>
            </div>

            {/* Hero image */}
            <div>
              <div
                className="relative flex justify-center items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {/*<img alt="Hero" className="mx-auto" height="504" src={require('../images/hero-image-01.jpg')} width="1024" />
              <a aria-controls="modal" className="absolute group" href="#0" onClick={(e) => { e.preventDefault(); setVideoModalOpen(true); }}>
                <svg className="w-16 h-16 sm:w-20 sm:h-20 hover:opacity-75 transition duration-150 ease-in-out" viewBox="0 0 88 88" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="a" x1="78.169%" x2="24.434%" y1="9.507%" y2="90.469%">
                      <stop offset="0%" stopColor="" stopOpacity=".8" />
                      <stop offset="100%" stopColor="" />
                    </linearGradient>
                  </defs>
                  <circle cx="44" cy="44" fill="url(#a)" r="44" />
                  <path className="fill-current text-blue-600" d="M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z" />
                </svg>
              </div></a>*/}
              </div>

              {/* Modal 
              <Modal
                ariaLabel="modal-headline"
                handleClose={() => setVideoModalOpen(false)}
                id="modal"
                show={videoModalOpen}
              >
                <div className="relative pb-9/16">
                  <iframe
                    allowFullScreen
                    className="absolute w-full h-full"
                    src="https://player.vimeo.com/video/174002812"
                    title="Video"
                  ></iframe>
                </div>
              </Modal>
              */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroHome;
