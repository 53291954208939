import React from "react";

function Process() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-10 pb-12 md:pt-16 md:pb-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h3 mb-4" data-aos="fade-up">
              Built with teams in mind
            </h2>
            <p
              className="text-lg text-black"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Give your team the toolset to properly transact inventory and
              acheive complete item retainment&#8212;leaving a log trail for
              integrity. To make things easier, you can also manage your team,
              roles, and access to insure continuity with your organization's
              policies.{" "}
            </p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                data-aos="fade-in"
                data-aos-delay="200"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
              ></div>
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  height="64"
                  rx="32"
                  width="64"
                />
                <path
                  className="stroke-current text-blue-300"
                  d="M21 23h22v18H21z"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                  strokeWidth="2"
                />
                <path
                  className="stroke-current text-blue-100"
                  d="M26 28h12M26 32h12M26 36h5"
                  strokeLinecap="square"
                  strokeWidth="2"
                />
              </svg>
              <h4 className="h4 mb-2">
                <span className="text-black">1</span>. Onboard
              </h4>
              <p className="text-lg text-black text-center">
                Bring your data from multiple formats to one platform. Need
                help? Our data migration specialists can assist.
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                data-aos="fade-in"
                data-aos-delay="400"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
              ></div>
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  height="64"
                  rx="32"
                  width="64"
                />
                <g fill="none" fillRule="evenodd">
                  <path
                    className="stroke-current text-blue-300"
                    d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2"
                    strokeLinecap="square"
                    strokeWidth="2"
                  />
                  <path
                    className="stroke-current text-blue-100"
                    d="M36 32l-4-3-4 3V22h8z"
                    strokeLinecap="square"
                    strokeWidth="2"
                  />
                </g>
              </svg>
              <h4 className="h4 mb-2">
                <span className="text-black">2</span>. Maintain
              </h4>
              <p className="text-lg text-black text-center">
                Manage inventory with speed and security in mind. Keep track of
                items in any high transaction environment.{" "}
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <svg
                className="w-16 h-16 mb-4"
                viewBox="0 0 64 64"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="fill-current text-blue-600"
                  height="64"
                  rx="32"
                  width="64"
                />
                <path
                  className="stroke-current text-blue-300"
                  d="M21 35l4 4 12-15"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                  strokeWidth="2"
                />
                <path
                  className="stroke-current text-blue-100"
                  d="M42 29h-3M42 34h-7M42 39H31"
                  strokeLinecap="square"
                  strokeWidth="2"
                />
              </svg>
              <h4 className="h4 mb-2">
                <span className="text-black">3</span>. Reconcile
              </h4>
              <p className="text-lg text-black text-center">
                Trust, but verify. With our powerful recon tools you can empower
                your team to swiftly verify your inventory.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
