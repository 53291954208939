import React, { useState } from "react";

import { Modal as AntModal } from "antd";
import FeaturesHome from "../partials/FeaturesHome";
import Footer from "../partials/Footer";
import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import News from "../partials/News";
import Newsletter from "../partials/Newsletter";
import PageIllustration from "../partials/PageIllustration";
import Process from "../partials/Process";
import Tabs from "../partials/Tabs";
import Target from "../partials/Target";

function Home() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/*<AntModal centered bodyStyle={{height: "700px"}} title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
    <iframe  scrolling="no"  style={{width:"75%",height:"100%"}} src="https://cdn.forms-content.sg-form.com/eacfac2b-e0f8-11eb-a629-9a5fe9ac330b"/>
  </AntModal>*/}
      <div className="flex flex-col min-h-screen overflow-hidden">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/* Page illustration */}
          <div
            aria-hidden="true"
            className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          >
            <PageIllustration />
          </div>

          {/*  Page sections */}

          <HeroHome setIsModalVisible={setIsModalVisible} />
          <Process />
          <FeaturesHome />
          <Tabs />

          {/*<Target />*/}
          <Newsletter />
        </main>

        {/*  Site footer */}
        <Footer />
      </div>
    </>
  );
}

export default Home;
