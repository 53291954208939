import React from "react";

function HeroAbout() {
  return (
    <section className="relative">
      {/* Background image */}
      <div className="absolute inset-0">
        <img
          alt="About"
          className="w-full h-full object-cover"
          height="394"
          src={require("../images/about-hero.jpg")}
          width="1440"
        />
        <div
          aria-hidden="true"
          className="absolute inset-0 bg-white opacity-75"
        ></div>
      </div>

      {/* Hero content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h1 mb-4" data-aos="fade-up">
              The story behind the project
            </h1>
            <p
              className="text-xl text-black mb-8"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              We know the struggle of finding great software that's not
              over-engineered, out-dated, and ridiculously priced. We've always
              strived to create the best software from every angle.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroAbout;
