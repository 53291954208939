import * as serviceWorker from "./serviceWorker";

import App from "./App";
import GA4React from "ga-4-react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

const ga4react = new GA4React("G-TKPRJ1TGX6");
(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log("Analytics Success."))
    .catch((err) => console.log("Analytics Failure."))
    .finally(() => {
      ReactDOM.render(
        <React.StrictMode>
          <Router>
            <App />
          </Router>
        </React.StrictMode>,
        document.getElementById("root")
      );
    });
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
