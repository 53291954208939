import { Link } from "react-router-dom";
import React from "react";

function Footer() {
  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div>
                {/* Logo */}
                <Link aria-label="Cruip" className="block" to="/">
                  <p className="text-black h4" data-aos="fade-up">
                    ever<span style={{ color: "#1890ff" }}>on</span>ics
                    <span>
                      <sup style={{ fontSize: 5, verticalAlign: "super" }}>
                        TM
                      </sup>
                    </span>
                  </p>
                </Link>
              </div>

              <div className="text-black">
                Our goal is to create cutting edge technology that helps to
                empower small businesses and large enterprises. We keep our
                software simple and our team lean so we can focus on creating
                great products.
              </div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className="md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8">
              {/* 2nd block */}
              <div className="text-sm">
                <h6 className="text-black font-medium mb-1">Products</h6>
                <ul>
                  <li className="mb-1">
                    <Link
                      className="text-black transition duration-150 ease-in-out"
                      to="#"
                    >
                      {" "}
                      <p className="mb-1" data-aos="fade-up">
                        ever<span style={{ color: "#1890ff" }}>on</span>ics
                        <span>
                          <sup style={{ fontSize: 5, verticalAlign: "super" }}>
                            TM
                          </sup>
                        </span>{" "}
                        inventory
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>

              {/* 3rd block */}
              <div className="text-sm">
                <h6 className="text-black font-medium mb-1">Resources</h6>
                <ul>
                  <li className="mb-1">
                    <Link
                      className="text-black hover:text-gray-100 transition duration-150 ease-in-out"
                      to="#"
                    ></Link>
                  </li>
                </ul>
              </div>

              {/* 4th block */}
              <div className="text-sm">
                <h6 className="text-black font-medium mb-1">Company</h6>
                <ul>
                  <li className="mb-1">
                    <Link
                      className="text-black hover:text-gray-100 transition duration-150 ease-in-out"
                      to="#"
                    ></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Social links */}
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li className="ml-4">
                <a
                  aria-label="Linkedin"
                  className="flex justify-center items-center text-blue-600 bg-gray-200 hover:text-gray-100 hover:bg-blue-600 rounded-full transition duration-150 ease-in-out"
                  href="https://www.linkedin.com/company/everonics/about/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                  </svg>
                </a>
              </li>
            </ul>

            {/* Copyrights note */}
            <div className="text-black text-sm mr-4">
              &copy;{" "}
              <a className="text-black" href="https://everonics.com">
                ever<span style={{ color: "#1890ff" }}>on</span>ics
                <span>
                  <sup style={{ fontSize: 4, verticalAlign: "super" }}>TM</sup>
                </span>
              </a>{" "}
              All rights reserved.{" "}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
