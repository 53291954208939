import "./css/style.scss";

import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import AOS from "aos";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import Contact from "./pages/Contact";
import Features from "./pages/Features";
import Help from "./pages/Help";
import Home from "./pages/Home";
import OnboardingSelection from "./pages/OnboardingSelection";
import PageNotFound from "./pages/PageNotFound";
import Pricing from "./pages/Pricing";
import Products from "./pages/Products";
import ResetPassword from "./pages/ResetPassword";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import { focusHandling } from "cruip-js-toolkit";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 600,
      easing: "ease-out-sine",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        {/*<Route path="/features">
          <Features />
  </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        */}
        <Route path="/about">
          <About />
        </Route>
        {/*
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/help">
          <Help />
        </Route>*/}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
}

export default App;
